import $ from 'jquery';

export function MobileAccordion() {
    $(function () {
        const OpenBtn = $('.Btn_open');
        const CloseBtn = $('.Btn_close');

		OpenBtn.stop().on('click', function() {
            let _self = $(this); //クリックしたボタン
            let content = _self.parent().children('.MobileAccordion_content'); // クリックしたボタンに対するコンテンツ
            let select =  _self.attr('aria-selected');// クリックしたボタンのariaの状態


            if(select === 'true') { // Openだった場合
                content.removeClass('-opened');
                _self.attr('aria-selected', false);
				_self.prev(CloseBtn).attr('aria-selected', true);
            } else {
                content.addClass('-opened');
                _self.attr('aria-selected', true);
				_self.prev(CloseBtn).attr('aria-selected', false);
            }
        });

        CloseBtn.on('click', function() {
            let _self = $(this); //クリックした閉じるボタン
            let content = _self.parent().children('.MobileAccordion_content'); //クリックした閉じるボタンの親要素

			content.removeClass('-opened');
			_self.next(OpenBtn).attr('aria-selected', false);
			_self.attr('aria-selected', true);
        });

    });
}
