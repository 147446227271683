import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'

import $ from 'jquery'
import { Accordion } from './includes/Accordion'
import { Scroll } from './includes/Scroll'
import { HeaderMenu, headerMenuInit } from './includes/HeaderMenu'
import { mobileMenuReady, mobileMenuInit } from './includes/MobileMenu'
import { SubNav } from './includes/SubNav'
import { Tab } from './includes/Tab'
import { MobileAccordion } from './includes/MobileAccordion'
import { ToggleMenu } from './includes/ToggleMenu'
import {ChangeLang} from "./includes/ChangeLang";
import {Product} from "./includes/Product";

$(function () {
	Scroll()
	Accordion()
	mobileMenuReady()
	HeaderMenu()
	SubNav()
	Tab()
	MobileAccordion()
	ToggleMenu()
	ChangeLang()
	Product()
})

headerMenuInit()
mobileMenuInit()
