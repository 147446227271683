import $ from 'jquery';
export function SubNav() {
	const withSubMenu = $('.Menu.-withsub');
	const subMenu =  $('.HeaderNav_subArea');

	function subMenuReset() {
		withSubMenu.attr('aria-expanded', 'false');
		subMenu.attr('aria-hidden', 'true');
	}
	function subMenuOpen() {
		withSubMenu.attr('aria-expanded', 'true');
		subMenu.attr('aria-hidden', 'false');
	}

	function toggleSubMenu() {
		withSubMenu.hover(function (){
			$(this).children('.HeaderNav_subArea').attr('aria-hidden', 'false');
		},
		function (){
			$(this).children('.HeaderNav_subArea').attr('aria-hidden', 'true');

		})
	}

	$(function () {
		toggleSubMenu();
	});
}
