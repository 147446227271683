import $ from 'jquery'

export function Tab() {
	$(function () {
		const Tab = $('.TabContainer_Tab li')

		Tab.stop().on('click', function () {
			let _self = $(this)
			let selected = _self.attr('aria-selected')

			// selected : true
			if (selected === 'true') return

			// 開いてるものを閉じる
			const Panels = $('.TabItem')
			Panels.attr('aria-hidden', true)
			Tab.attr('aria-selected', false)

			let id = _self.attr('aria-controls')
			let id_panel = $('#' + id)

			id_panel.attr('aria-hidden', false)
			_self.attr('aria-selected', true)
		})
	})
}
