import $ from 'jquery'

export function ChangeLang() {
	const Btn = $('.Header_lang.-pc');
	const LangBox = $('.ChangeLang_box');
	const MenuBase = $('.Header')
	const breakpoint = 1200

	// 初期設定
	function LangBoxReset() {
		LangBox.attr('aria-hidden','true');
		LangBox.css('display','');
	}

	// LangBoxの開閉
	function LangBoxOpen() {
		LangBox.attr('aria-hidden','false');
		LangBox.fadeIn();
	}
	function LangBoxClose() {
		LangBox.attr('aria-hidden', 'true');
		LangBox.fadeOut();
	}

	// Button
	function changeButtonClose() {
		Btn.attr('aria-selected','false');
	}
	function changeButtonOpen() {
		Btn.attr('aria-selected','true');
	}


	// ButtonクリックでLangBoxオープン
	function toggleLang() {
		Btn.hover(function () {
			if($(this).attr('aria-selected') === 'false') {
				LangBoxOpen();
				changeButtonOpen();
			} else {
				LangBoxClose();
				changeButtonClose();
			}
		});
	}

	// ロード時とリサイズ時にリセット
	$(window).on('load', function () {
		changeButtonClose();
		LangBoxReset();
	});
	$(window).on('resize', function () {
		changeButtonClose();
		LangBoxReset();
	});

	$(function () {
		toggleLang();
	});

}
