import $ from 'jquery';

export function ToggleMenu() {
    const Btn = $('.ToggleNav_btn');
    const SubMenu = $('.ToggleNav_content');
	const breakpoint = 1200

    // 初期設定
    function menuReset() {
		SubMenu.attr('aria-hidden','true');
		SubMenu.css('display','');
		SubMenu.addClass('-closed');
		Btn.removeClass('-opened');
		Btn.addClass('-closed');
    }


    // SubMenuの開閉
    function MenuOpen() {
		SubMenu.addClass('-opened');
		SubMenu.removeClass('-closed');
		SubMenu.attr('aria-hidden','false');
		SubMenu.slideDown();
    }
    function MenuClose() {
    	SubMenu.removeClass('-opened');
    	SubMenu.addClass('-closed');
		SubMenu.attr('aria-hidden', 'true');
		SubMenu.slideUp();
    }

    // Button
    function changeButtonClose() {
        Btn.attr('aria-selected','false');
        Btn.removeClass('-opened');
        Btn.addClass('-closed');
    }
    function changeButtonOpen() {
        Btn.attr('aria-selected','true');
		Btn.removeClass('-closed');
		Btn.addClass('-opened');
    }


    // ButtonクリックでSubMenuオープン
    function toggleMenu() {
        Btn.click(function () {
            if($(this).attr('aria-selected') === 'false') {
                MenuOpen();
                changeButtonOpen();
            } else {
                MenuClose();
                changeButtonClose();
            }
        });
    }

    // ロード時とリサイズ時にリセット
    $(window).on('load', function () {
		changeButtonClose();
		menuReset();
    });
    $(window).on('resize', function () {
		changeButtonClose();
		menuReset();
    });

    $(function () {
        toggleMenu();
    });

}
