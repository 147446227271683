import $ from 'jquery'

export function headerMenuInit() {
	const HeaderNav = $('.Header_nav')
	const breakpoint = 1200

	//1100px未満の場合、 HeaderNav に.-mobileを付与
	function MobileHeader() {
		$(HeaderNav).addClass('-mobile')
	}
	function resetHeader() {
		$(HeaderNav).removeClass('-mobile')
	}

	$(window).on('load', function () {
		if (breakpoint > window.innerWidth) {
			MobileHeader()
		} else {
			resetHeader()
		}
	})
	$(window).on('resize', function () {
		if (breakpoint > window.innerWidth) {
			MobileHeader()
			HeaderNav.css('display', '')
		} else {
			resetHeader()
			HeaderNav.css('display', '')
		}
	})
}

export function HeaderMenu() {
	const Menu = $('.Menu')
	const MenuBase = $('.Header')

	function HoverEvent() {
		MenuBase.hover(
			function () {
				MenuBase.addClass('-hasHover')
			},
			function () {
				MenuBase.removeClass('-hasHover')
			},
		)
	}
	$(function () {
		HoverEvent()
	})
}
